import {css, Theme, useTheme} from "@emotion/react";
import {backgroundColor, m, mb, onDesktop, p, pb} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {getTextVariantStyleFor, Text} from "@pg-design/text";

import {IEntryCategory} from "../../types/Category";
import {ContentType} from "../../types/Entry";
import {InfoTextBlock} from "../InfoTextBlock";

interface IHeroProps {
    className?: string;
    title: string;
    images: {
        img_840x523: string;
        img_744x463: string;
        img_430x268: string;
    } | null;
    lead: string;
    publication_date: string;
    region_name: string | null;
    category: Array<IEntryCategory> | null;
    id: number;
    content: Array<ContentType> | null;
    source: string | null;
    author: string | null;
}

export const Hero = ({
    title,
    images,
    lead,
    publication_date,
    category,
    region_name,
    content,
    source,
    author,
    className
}: IHeroProps) => {
    const theme = useTheme();

    return (
        <div className={className} css={[backgroundColor("#fff")]}>
            {images && (
                <figure css={imgHolder}>
                    <Picture
                        css={picture}
                        sources={[
                            {minWidthPX: 0, src: images.img_430x268, width: 430, height: 268},
                            {
                                minWidthPX: theme.breakpoints.numeric.xs,
                                src: images.img_744x463,
                                width: 744,
                                height: 463
                            },
                            {
                                minWidthPX: theme.breakpoints.numeric.md,
                                src: images.img_840x523,
                                width: 840,
                                height: 523
                            }
                        ]}
                        alt={title}
                    />
                </figure>
            )}

            <div css={textWrapper}>
                <InfoTextBlock
                    css={mb(2)}
                    publication_date={publication_date}
                    region_name={region_name}
                    category={category}
                />

                <Text variant="headline_1" css={mb(4)}>
                    {title}
                </Text>

                <Text variant="body_copy_0" strong css={mb(3)}>
                    {lead}
                </Text>

                {content?.map((content) => {
                    return (
                        <div key={content.id} css={contentWrapper} dangerouslySetInnerHTML={{__html: content.value}} />
                    );
                })}

                {author && <Text variant="body_copy_1">Autor: {author}</Text>}

                {source && <Text variant="body_copy_1">Źródło: {source}</Text>}
            </div>
        </div>
    );
};

const picture = css`
    > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const imgHolder = css`
    margin: 0;
`;

const textWrapper = css`
    ${p(2, 3, 2, 3)};
`;

const textVerticalSpace = css`
    margin: 0;
    ${m(2, 0, 2, 0)};

    ${onDesktop(m(3, 0, 3, 0))}
`;

const contentWrapper = (theme: Theme) => css`
    ${getTextVariantStyleFor("body_copy_0")(theme)};
    ${pb(3)};

    a {
        text-decoration: underline;

        &:hover {
            color: ${theme.colors.highlight};
        }
    }

    h2,
    h3,
    h4 {
        ${textVerticalSpace};
    }

    h2 {
        ${getTextVariantStyleFor("headline_2")(theme)};
    }

    h3 {
        ${getTextVariantStyleFor("headline_3")(theme)};
    }

    h4 {
        ${getTextVariantStyleFor("headline_4")(theme)};
    }

    p,
    span {
        ${textVerticalSpace};
    }

    img {
        max-inline-size: 100%;
        block-size: auto;
    }

    ul {
        ${getTextVariantStyleFor("body_copy_0")(theme)};
        ${m(0, 0, 2, 3)}

        ${onDesktop(m(0, 0, 4, 3))}
    }

    li {
        list-style-type: disc;
    }
`;
