import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {css, useTheme} from "@emotion/react";
import {Badge} from "@pg-design/badge";
import {Button} from "@pg-design/button";
import {Col} from "@pg-design/grid";
import {
    borderRadius,
    calculateRemSize,
    flex,
    flexDirection,
    mb,
    mr,
    p,
    pb,
    ph,
    position,
    pt,
    underline,
    w100
} from "@pg-design/helpers-css";
import {ClockIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";
import {compile} from "path-to-regexp";

import {dataLayer} from "../../../../../../utils/data_layer";
import {getPublicationAccessStatus} from "../../../../../../utils/get_publication_access_status";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {AccessStatus, useAccessStatus} from "../../../../../../utils/hooks/useAccessStatus";
import {chartsRoutes, getLocalizedUrl} from "../../../../common/app/routing/charts_routes";
import {flexGrow, flexShrink} from "../../../../components/css_helpers/flex";
import {selectAuthStatus, setAuthModal} from "../../../auth/auth_slice";
import {selectUserData} from "../../../users/users_slice";
import {useDownloadFreePublicationMutation} from "../../api/download_publication";
import {PublicationDistinctionType, PublicationPricingType} from "../../types/Publication";
import {DownloadPublicationModal} from "../download_publication_modal/DownloadPublicationModal";
import {FreeDownloadSuccessModal} from "../FreeDownloadSuccessModal";

interface IFeaturedPublicationItem {
    publication: {
        id: number;
        image: string;
        name: string;
        pricing_type: PublicationPricingType;
        date_of_publication: string;
        price: number;
        promotional_price: number | null;
        link: string | null;
        file: string | null;
        distinction_type: PublicationDistinctionType;
        slug: string;
    };
}

export const FeaturedPublicationItem = ({publication}: IFeaturedPublicationItem) => {
    const {t, i18n} = useTranslation();
    const toPath = useMemo(() => {
        return compile(getLocalizedUrl(chartsRoutes[i18n.language].publications.details, i18n.language));
    }, [i18n.language]);
    const theme = useTheme();
    const {isFreeForBD, isFree, isPaid} = getPublicationAccessStatus(publication.pricing_type);
    const accessStatus = useAccessStatus();
    const userData = useAppSelector(selectUserData);
    const [isDownloadPublicationModalOpen, setIsDownloadPublicationModalOpen] = useState(false);
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const [downloadPublication, {reset}] = useDownloadFreePublicationMutation({
        fixedCacheKey: `orderPublication${publication.id}`
    });
    const dispatch = useAppDispatch();

    const downloadWithUserComFree = () => {
        if (!isLoggedIn || (userData && !userData.bigdata_marketing_consent)) {
            dataLayer({
                event: "free_publication_form_open",
                publication: publication.name
            });
            setIsDownloadPublicationModalOpen(true);
        }

        if (isLoggedIn && userData && userData.bigdata_marketing_consent) {
            downloadPublication({
                publication: publication.id,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email_form_value: userData.email,
                bigdata_marketing_consent: userData.bigdata_marketing_consent
            })
                .unwrap()
                .then(() => {
                    reset();
                    setSuccessModalOpen(true);
                })
                .catch((err) => err.data.publication && toast.error(err.data.publication[0]));
        }
    };

    const downloadWithUserComPaid = () => {
        dataLayer({
            event: "paid_publication_form_open",
            publication: publication.name
        });
        setIsDownloadPublicationModalOpen(true);
    };

    return (
        <>
            <FreeDownloadSuccessModal isOpen={isSuccessModalOpen} setOpen={setSuccessModalOpen} />

            <DownloadPublicationModal
                isOpen={isDownloadPublicationModalOpen}
                setOpen={setIsDownloadPublicationModalOpen}
                id={publication.id}
                pricing_type={publication.pricing_type}
                price={publication.price}
                promotional_price={publication.promotional_price}
                name={publication.name}
            />

            <Col as="li" lg={4} md={3} sm={4} css={listItemWrapper}>
                <Link
                    to={toPath({
                        publicationId: String(publication.id),
                        slug: publication.slug
                    })}
                    onClick={() => window.scrollTo(0, 0)}
                    css={listItem}
                >
                    <div css={[flexGrow(0), flexShrink(0)]}>
                        <Image
                            css={publicationListImage}
                            alt={publication.name}
                            width="264"
                            height="164"
                            ratio={{xs: "264:164"}}
                            src={publication.image}
                        />

                        {publication.distinction_type === PublicationDistinctionType.NEW && (
                            <Badge variant="label_warning" css={distinction}>
                                Nowe!
                            </Badge>
                        )}

                        {publication.distinction_type === PublicationDistinctionType.RECOMMENDED && (
                            <Badge variant="label_success" css={distinction}>
                                Polecamy!
                            </Badge>
                        )}
                    </div>

                    <div css={[ph(2), pt(2), flexGrow(0), flexShrink(0)]}>
                        <Text variant="body_copy_1">{publication.name}</Text>
                    </div>

                    <div css={[p(2), flex("center", "flex-start"), flexGrow(1), flexShrink(1)]}>
                        <Text variant="body_copy_2" color={theme.colors.gray[700]}>
                            <ClockIcon css={mr(1.5)} fill={theme.colors.gray[700]} size="2" />
                            {new Date(publication.date_of_publication).toLocaleDateString()}
                        </Text>
                    </div>

                    <div css={[ph(2), pb(2), flexGrow(0), flexShrink(0)]}>
                        {!isFree ? (
                            <div css={mb(3)}>
                                <Text css={[mb(0.5)]} as="p" variant="body_copy_2" strong>
                                    {publication.pricing_type === PublicationPricingType.FREE_BD_USER
                                        ? `Bezpłatnie*/`
                                        : publication.promotional_price
                                          ? `${publication.promotional_price}*/`
                                          : ""}
                                    {publication.price}zł
                                </Text>

                                {(publication.promotional_price || isFreeForBD) &&
                                    (isLoggedIn ? (
                                        <Text variant="info_txt_1">
                                            {accessStatus === AccessStatus.FULL
                                                ? `* Twoja cena - posiadasz aktywny dostęp do platformy BIG${String.fromCharCode(
                                                      160
                                                  )}DATA`
                                                : "* dla klientów RynekPierwotny.pl posiadających aktywne konto na platformie BIG DATA"}
                                        </Text>
                                    ) : (
                                        <Text variant="info_txt_1">
                                            * dla klientów RynekPierwotny.pl posiadających aktywne konto na platformie
                                            BIG&nbsp;DATA - Masz już konto?&nbsp;
                                            <span
                                                css={underline}
                                                onClick={(e) => {
                                                    dispatch(setAuthModal("login"));
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >
                                                Zaloguj się
                                            </span>
                                            , nie masz jeszcze dostępu?&nbsp;
                                            <span
                                                css={underline}
                                                onClick={(e) => {
                                                    dispatch(setAuthModal("registerUser"));
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >
                                                Zarejestruj się
                                            </span>
                                        </Text>
                                    ))}
                            </div>
                        ) : (
                            <Text css={[mb(3)]} variant="info_txt_1" strong>
                                Bezpłatnie
                            </Text>
                        )}

                        {(isPaid || (isFreeForBD && accessStatus !== AccessStatus.FULL)) && (
                            <Button
                                variant="filled_primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    downloadWithUserComPaid();
                                }}
                                css={w100}
                            >
                                {t("publications.landing.order_button")}
                            </Button>
                        )}

                        {(isFree || (isFreeForBD && accessStatus === AccessStatus.FULL)) && (
                            <Button
                                variant="filled_primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    downloadWithUserComFree();
                                }}
                                css={w100}
                            >
                                {t("publications.featured.download")}
                            </Button>
                        )}
                    </div>
                </Link>
            </Col>
        </>
    );
};

const listItemWrapper = css`
    ${mb(4)};
    ${flex("flex-start", "center")}
`;

const listItem = css`
    height: 100%;
    width: 100%;
    min-height: 40rem;
    max-width: 42rem;
    ${flex()};
    ${flexDirection("column")};
    ${borderRadius(2)};
    background-color: #fff;
    position: relative;
`;

const distinction = css`
    ${position("absolute")};
    top: ${calculateRemSize(1)};
    left: 1rem;
`;

const publicationListImage = css`
    ${borderRadius(2, 2, 0, 0)};
    max-height: 164px;

    > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;
