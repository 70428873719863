import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IRootState} from "../../../store/store";
import {IDashboardFooterState, IHomepageFooterState} from "../path_to_action/state/footer/IFooterState";

interface IFooterState {
    footer?: IHomepageFooterState | IDashboardFooterState;
}

const initialState: IFooterState = {};

export const footerSlice = createSlice({
    name: "footerState",
    initialState,
    reducers: {
        setFooter: (state, action: PayloadAction<IHomepageFooterState | IDashboardFooterState>) => {
            state.footer = action.payload;
        }
    }
});

export const {setFooter} = footerSlice.actions;

export const selectFooter = (state: IRootState) => state.footerState.footer;
