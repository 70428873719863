import React, {useMemo} from "react";
import ReactGoogleMapsLoader from "react-google-maps-loader";
import {useLocation} from "react-router-dom";

import {CitySlug, getCityOption} from "../../../../config/cities";
import {useRpRequest} from "../../../../utils/hooks/useRpRequest";
import {googleMapsApiKey, rpWebUrl} from "../../common/app/read_charts_web_environment_variables";
import {mapPathnameToOfferType, OfferType} from "../../common/app/routing/offer_type";
import {IMapOffer} from "./map_offer_box/MapOfferBox";
import LazyGoogleMap from "./LazyGoogleMap";
import {PolygonDefinition, PolygonGroupDefinition} from "./polygons";

import markerType1 from "../../features/monitoring/assets/marker_offer_list_type1.svg";
import markerType2 from "../../features/monitoring/assets/marker_offer_list_type2.svg";
import GoogleMaps = ReactGoogleMapsLoader.GoogleMaps;

interface IProps {
    city: CitySlug | null;
    onMarkerClick: (id: number) => void;
}

export interface IPolygon {
    area: {
        geo_area: {
            type: string;
            coordinates: [number, number][][][];
        };
    };
    full_name_reverted: string;
    id: number;
    short_name: string;
    stats: {properties_count_for_sale: number};
}

// get polygons by region id https://rynekpierwotny.pl/api/v2/regions/region/8647/?s=region-list-map
// get polygons by region slug https://rynekpierwotny.pl/api/s/v2/regions/region/?s=region-list-map&slug=warszawa-i-okolice
// get related regions ids https://rynekpierwotny.pl/api/regions/region/?i%5B%5D=full_name&full_name=warszawa

export const GoogleMap = React.memo(({city, onMarkerClick}: IProps) => {
    const regionId = getRegionIdBySlug(city);
    const {pathname} = useLocation();
    const offerType = mapPathnameToOfferType(pathname);
    const offerTypeParams = new URLSearchParams();
    (offerType === OfferType.PROPERTY || offerType === OfferType.FLAT) && offerTypeParams.append("type", "1");
    (offerType === OfferType.PROPERTY || offerType === OfferType.HOUSE) && offerTypeParams.append("type", "2");

    const {data: polygonsData} = useRpRequest<IPolygon[], {results: IPolygon[]}>(
        `${rpWebUrl}/api/s/v2/regions/region/?s=region-list-map&slug=${getCityOption(city).requestMapSlug}`,
        (res) => res.results
    );
    const offersRes = useRpRequest<IMapOffer[], {results: IMapOffer[]}>(
        `${rpWebUrl}/api/s/v2/offers/offer/?s=offer-list-map&display_type=1&display_type=4&distance=0&for_sale=True&limited_presentation=False&page_size=1000&region=${regionId}&show_on_listing=True&${offerTypeParams.toString()}`,
        (res) => res.results
    );

    const coords = polygonsData?.[0].area.geo_area.coordinates ?? [[]];

    const mapConfig: google.maps.MapOptions = {
        gestureHandling: "cooperative",
        mapTypeControl: false,
        streetViewControl: false,
        maxZoom: 17,
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: 12 // google.maps.ControlPosition.BOTTOM_RIGHT
        },
        zoomControl: true
    };

    const polygonsCoords = {
        coords,
        options: {
            fillColor: "#ffffff",
            fillOpacity: 0.3,
            strokeColor: "#FF4085",
            strokeOpacity: 1
        }
    };

    const polygons: Record<string, PolygonGroupDefinition> = {
        default: {
            list: [polygonsCoords] as PolygonDefinition[]
        }
    };

    const markers = useMemo(
        () => ({
            default: {
                list:
                    offersRes.data?.map((offer) => {
                        const {vendor, type} = offer;
                        const markerTooltip = `<div><p style="font-size: 12px; font-weight: bold; margin: 0; padding: 0">${
                            offer.name
                        }</p><p style="font-size: 12px; margin: 0 0 3px 0; padding: 0; color: #CCCCCC;">${
                            vendor && vendor.name
                        }</p><p style="margin: 0; padding: 0">liczba mieszkań: ${
                            offer.stats.properties_count_for_sale
                        }</p></div>`;

                        return {
                            coords: offer.geo_point.coordinates,
                            onOpen: () => onMarkerClick(offer.id),
                            infoWindow: {content: markerTooltip},
                            icon: type === 1 ? markerType1 : markerType2
                        };
                    }) ?? []
            }
        }),
        [offersRes.data, pathname]
    );

    return (
        <ReactGoogleMapsLoader
            params={{key: googleMapsApiKey}}
            render={(googleMaps: GoogleMaps) =>
                googleMaps && (
                    <LazyGoogleMap
                        config={mapConfig}
                        polygons={polygons}
                        markers={markers}
                        googleMaps={googleMaps}
                        infoWindow
                        fitBoundsOnUpdate
                    />
                )
            }
        />
    );
});

const getRegionIdBySlug = (slug: CitySlug | null) => {
    switch (slug) {
        case CitySlug.WARSZAWA:
            return 8647;
        case CitySlug.WARSZAWA_AGGLOMERATION:
            return 52253;
        case CitySlug.WARSZAWA_SUBURBS:
            return 52262;
        case CitySlug.KRAKOW:
            return 11158;
        case CitySlug.KRAKOW_AGGLOMERATION:
            return 52258;
        case CitySlug.KRAKOW_SUBURBS:
            return 52265;
        case CitySlug.WROCLAW:
            return 26955;
        case CitySlug.WROCLAW_AGGLOMERATION:
            return 52257;
        case CitySlug.WROCLAW_SUBURBS:
            return 52266;
        case CitySlug.GDANSK:
            return 52255;
        case CitySlug.POZNAN:
            return 30892;
        case CitySlug.POZNAN_AGGLOMERATION:
            return 52254;
        case CitySlug.POZNAN_SUBURBS:
            return 52263;
        case CitySlug.LODZ:
            return 16187;
        case CitySlug.LODZ_AGGLOMERATION:
            return 52259;
        case CitySlug.LODZ_SUBURBS:
            return 52267;
        case CitySlug.SZCZECIN:
            return 32657;
        case CitySlug.OLSZTYN:
            return 26804;
        case CitySlug.BIALYSTOK:
            return 9692;
        case CitySlug.ZIELONA_GORA:
            return 48806;
        case CitySlug.BYDGOSZCZ:
            return 22550;
        case CitySlug.KATOWICE:
            return 53799;
        case CitySlug.KIELCE:
            return 36190;
        case CitySlug.LUBLIN:
            return 13220;
        // case CitySlug.Opole:
        //     return 12168;
        case CitySlug.RZESZOW:
            return 33659;
        default:
            return 0;
    }
};
