import {lazy, useMemo} from "react";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {flex, mr} from "@pg-design/helpers-css";
import {Options, SeriesMapOptions} from "highcharts";

import {IMonthDistrictStats} from "../../../../api/src/db_queries/month_district_stats_query";
import {IMonthDistrictTypeStats} from "../../../../api/src/db_queries/month_district_type_stats_query";
import {mapParamSlugToCitySlug} from "../../../../config/cities";
import {findMaxValue} from "../../../../utils/misc";
import {formatColoredMapTooltip} from "../../../../utils/tooltips";
import {maps} from "../../common/app/constants/maps";
import {breakpoints} from "../../common/app/styles/breakpoints";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";

const Chart = lazy(() => import("./ChartDefaultExport"));

export interface IDistrictsMapDetailedProps extends IChartProps {
    city: string;
    data: IMonthDistrictStats[] | IMonthDistrictTypeStats[];
    unit?: string; // pluralized translation must exist in translation files under "common" key
    valueField: string;
    legendItems?: LegendItemType[];
    legendType?: "quantitative" | "pricing";
    legendColors?: string[];
    valueMultiplier?: number;
    hideNullValues?: boolean;
    roundValues?: boolean;
    grayNullValues?: boolean;
}

type LegendItemType = [number, number, string, string];

export const DistrictsMapDetailed = (props: IDistrictsMapDetailedProps) => {
    const {
        city,
        data,
        valueField,
        unit,
        legendItems,
        legendType,
        legendColors,
        valueMultiplier = 1,
        hideNullValues,
        roundValues,
        grayNullValues
    } = props;

    const {i18n} = useTranslation();
    const citySlug = mapParamSlugToCitySlug(city) ?? "warszawa";
    const highestValue = findMaxValue(data.map((el) => el[valueField as string]) as number[]);

    let legend: LegendItemType[] = [];

    if (!legendItems && !legendType && !legendColors) {
        throw new Error("Passing either legendItems or legendType and legendColors prop is required");
    } else if (legendItems && highestValue > 0) {
        legend = legendItems;
    } else if (legendType && legendColors && highestValue > 0) {
        legend = dynamicDistrictMapLegendValues(highestValue, legendType, legendColors);
    }

    const series: SeriesMapOptions[] = useMemo(() => {
        if (data && data.length > 0) {
            return [
                {
                    ...maps[citySlug][0],
                    data: data.map((district) => {
                        // TODO: fix type
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const value: number = district[valueField];
                        const valueWithSwappedZero = value > 0 ? value : 0.0000001;
                        const multipliedValue = valueWithSwappedZero * valueMultiplier;
                        const legendWithGrayOnNullValues: LegendItemType[] = [
                            [0, 0, "Brak danych", "rgb(230,235,245)"],
                            ...legend
                        ];

                        const colorArray = grayNullValues ? legendWithGrayOnNullValues : legend;
                        const color = colorArray.find((el) => value >= el[0] && value <= el[1])?.[3];

                        return {
                            color,
                            id: district.slug_district,
                            value: multipliedValue
                        };
                    })
                }
            ];
        }

        return [{...maps[city][0]}];
    }, [data, citySlug]);

    const options: Options = {
        chart: {
            animation: false
        },
        tooltip: {
            className: "colored-map-tooltip",
            formatter: function () {
                return formatColoredMapTooltip(this, hideNullValues, roundValues, i18n, unit);
            }
        },
        responsive: {
            rules: [
                {
                    chartOptions: {
                        chart: {
                            height: 500
                        }
                    },
                    condition: {
                        minWidth: 400
                    }
                }
            ]
        }
    };

    return (
        <StyledWrapper>
            <StyledContent>
                <div>
                    <SuspendedChart>
                        <Chart {...props} customOptions={options} series={series} type="map" />
                    </SuspendedChart>
                </div>

                <StyledLegendLabels>
                    {legend.map(([_, __, label, color], i) => (
                        <div key={i}>
                            <StyledDot color={color} />
                            {label}
                        </div>
                    ))}
                </StyledLegendLabels>
            </StyledContent>
        </StyledWrapper>
    );
};

const dynamicDistrictMapLegendValues = (
    highestValue: number,
    legendType: "quantitative" | "pricing",
    colors: string[]
): LegendItemType[] => {
    let legendItems: LegendItemType[] = [];

    const formatLabelValueThousands = (value: number, maxValue: number): string => {
        if (value === Infinity) {
            return `${maxValue / 1000}k`;
        }

        return value >= 1000 ? `${value / 1000}k` : value.toString();
    };

    if (legendType === "quantitative") {
        const availableCeilings = [100, 200, 500, 1000, 2000];

        const percentages = [0.1, 0.2, 0.5, 1, 1];

        const lastCeiling = availableCeilings[availableCeilings.length - 1];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore failing type checking despite relevant tsconfig setting
        for (const [i, ceiling] of availableCeilings.entries()) {
            if (highestValue <= ceiling || i === availableCeilings.length - 1) {
                legendItems = new Array(5).fill(null).map((_: null, n) => {
                    const minValue = n === 0 ? 0 : ceiling * percentages[n - 1];

                    const maxValue = n === colors.length - 1 ? Infinity : ceiling * percentages[n];

                    let label = "";

                    switch (n) {
                        case 0:
                            label = `<${formatLabelValueThousands(ceiling * percentages[n], lastCeiling)}`;
                            break;
                        case 1:
                        case 2:
                        case 3:
                            label = `${formatLabelValueThousands(minValue, lastCeiling)}-${formatLabelValueThousands(
                                maxValue,
                                lastCeiling
                            )}`;
                            break;
                        case 4:
                            label = `>${formatLabelValueThousands(minValue, lastCeiling)}`;
                            break;
                    }

                    return [minValue, maxValue, label, colors[n]];
                });

                break;
            }
        }
    }

    if (legendType === "pricing") {
        const availableCeilings = [10000, 15000, 20000];

        const ranges = [
            [5000, 7000, 9000, 10000, 10000],
            [7000, 9000, 12000, 15000, 15000],
            [7000, 10000, 15000, 20000, Infinity]
        ];

        const lastCeiling = availableCeilings[availableCeilings.length - 1];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore failing type checking despite relevant tsconfig setting
        for (const [i, ceiling] of availableCeilings.entries()) {
            if (highestValue <= ceiling || i === availableCeilings.length - 1) {
                legendItems = new Array(5).fill(null).map((_: null, n) => {
                    const minValue = n === 0 ? 0 : ranges[i][n - 1];

                    const maxValue = n === legendItems.length - 1 ? Infinity : ranges[i][n];

                    let label = "";

                    switch (n) {
                        case 0:
                            label = `<${formatLabelValueThousands(ranges[i][n], lastCeiling)}`;
                            break;
                        case 1:
                        case 2:
                        case 3:
                            label = `${formatLabelValueThousands(minValue, lastCeiling)}-${formatLabelValueThousands(
                                maxValue,
                                lastCeiling
                            )}`;
                            break;
                        case 4:
                            label = `>${formatLabelValueThousands(maxValue, lastCeiling)}`;
                            break;
                    }

                    return [minValue, maxValue, label, colors[n]];
                });

                break;
            }
        }
    }

    return legendItems;
};

const StyledWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 2rem;
    ${flex("center", "center")};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        align-items: flex-start;
        padding-top: 60px;
    }
`;

const StyledContent = styled.div`
    width: 100%;
    ${flex("flex-start", "flex-start")};
    flex-direction: column;
    flex-wrap: wrap;

    & > div {
        width: 100%;
    }
`;

const StyledLegendLabels = styled.div`
    ${flex("center", "center")};
    gap: 1rem;
    flex-wrap: wrap;
    padding: 2rem;
    cursor: pointer;
    margin-top: 2rem;
    line-height: 1;
    color: #333333;
    font-weight: 600;
    font-size: 1.2rem;

    @media (min-width: ${breakpoints.md}) {
        justify-content: space-around;
        gap: 2rem;
        max-width: 75%;
        margin: 0 auto;
    }

    & > div {
        display: flex;
    }
`;

const StyledDot = styled.span<{color: string}>`
    width: 12px;
    height: 12px;
    ${mr(1)}
    display: inline-block;
    border-radius: 2px;
    background: ${(props) => props.color};
`;
