import {backendApiPath, chartsApiPath} from "./api_path";

export const scenarios: string[] = [
    "home",
    "dashboard",
    "prices",
    "offer",
    "demand",
    "sold",
    "added",
    "rp-price",
    "rp-price-active",
    "rp-price-avg",
    "rp-price-avg-rooms",
    "rp-avg"
];

export const publicScenarios = [
    "home",
    "dashboard",
    "rp-price",
    "rp-price-active",
    "rp-price-avg",
    "rp-price-avg-rooms",
    "rp-avg"
];

export const pathsWithoutScenario = [chartsApiPath.currentDate, backendApiPath.cookies, chartsApiPath.newsletter];
