import {IEntryCategory} from "./Category";

export enum EntryType {
    ARTICLE = 1,
    BIG_DATA_NEWS = 3
}

export interface ContentType {
    id: number;
    type: string;
    value: string;
}

export interface IEntry {
    author: string | null;
    is_highlighted: boolean;
    category: Array<IEntryCategory> | null;
    content: Array<ContentType> | null;
    id: number;
    image: string | null;
    images: {
        img_840x523: string;
        img_744x463: string;
        img_430x268: string;
        img_332x208: string;
        img_385x193: string;
        img_600x300: string;
    } | null;
    lead: string;
    publication_date: string;
    region: string | null;
    region_name: string | null;
    slug: string;
    source: string | null;
    tags: Array<IEntryCategory> | null;
    title: string;
    type: EntryType;
}
