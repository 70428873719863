import {css} from "@emotion/react";
import {flexAbsoluteCenter, mb} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";

import {IEntry} from "../../../../types/Entry";
import {NoContent} from "../../../NoContent";
import {ArticleListItem} from "./ArticleListItem";

export interface IArticleListProps {
    className?: string;
    articles: Array<IEntry>;
    isLoading: boolean;
}

export const ArticleList = ({articles, isLoading, className}: IArticleListProps) => {
    return (
        <ul className={className}>
            {articles &&
                !!articles.length &&
                articles.map((article) => (
                    <ArticleListItem
                        css={listItem}
                        key={article.id}
                        title={article.title}
                        images={article.images}
                        lead={article.lead}
                        publication_date={article.publication_date}
                        region_name={article.region_name}
                        category={article.category}
                        id={article.id}
                        type={article.type}
                        slug={article.slug}
                        is_highlighted={article.is_highlighted}
                    />
                ))}

            {!isLoading && !articles.length && <NoContent />}

            {isLoading && (
                <div css={flexAbsoluteCenter}>
                    <Loader />
                </div>
            )}
        </ul>
    );
};

const listItem = css`
    &:not(:last-of-type) {
        ${mb(3)};
    }
`;
