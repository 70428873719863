import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {Global} from "@emotion/react";
import {zIndex} from "@pg-design/helpers-css";

import {useAppDispatch, useAppSelector} from "../../../../utils/hooks/store_hooks";
import {useRegisterModalPopup} from "../../../../utils/hooks/useRegisterModalPopup";
import {selectResponseStatus, selectUrl, setAppStatus} from "../../features/app_status/app_status_slice";
import {setCookies} from "../../features/auth/api/setCookies";
import {selectCurrentAuthModal, setAuthModal} from "../../features/auth/auth_slice";
import {AuthModal} from "../../features/auth/components/auth_modal/AuthModal";
import {Error404} from "../../features/errors/views/Error404";
import {Error500} from "../../features/errors/views/Error500";
import {ResponseStatus} from "../../features/ssr/path_to_action/state/app_status/IAppStatusState";
import {generateMetaData} from "../../server/meta_data/generate_meta_data";
import {setClientMetaData} from "../meta_data/set_client_meta_data";
import {ZIndex} from "./constants/z_index";
import {routes} from "./routing/routes";
import {globalStyles} from "./styles/global";

export const App = () => {
    const {pathname} = useLocation();
    const history = useHistory();
    const {i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const currentAuthModal = useAppSelector(selectCurrentAuthModal);
    const responseStatus = useAppSelector(selectResponseStatus);
    const url = useAppSelector(selectUrl);
    // fetch csrf token and session id cookies
    useEffect(() => {
        (async () => await setCookies())();
    }, []);

    // @TODO refactor do oddzielnego hooka
    const [shouldMetaDataUpdate, setMetaDataUpdate] = useState(false);
    history.listen(() => {
        setMetaDataUpdate(true);
    });
    useEffect(() => {
        (async () => {
            const metaData = await generateMetaData(pathname, i18n);
            setClientMetaData(metaData);
            setMetaDataUpdate(false);
        })();
    }, [shouldMetaDataUpdate]);
    // Redirect if app status tells us to do so, clear status and url after redirecting
    useEffect(() => {
        if ([ResponseStatus.REDIRECT_301, ResponseStatus.REDIRECT_302].includes(responseStatus) && url) {
            url.startsWith("http") ? (window.location.href = url) : history.push(url);
            dispatch(setAppStatus({responseStatus: ResponseStatus.DEFAULT, url: ""}));
        }
    }, [responseStatus, url]);
    useRegisterModalPopup();

    return (
        <React.StrictMode>
            <Global styles={globalStyles} />
            {responseStatus === ResponseStatus.PAGE_500 ? (
                <Error500 />
            ) : responseStatus === ResponseStatus.PAGE_404 ? (
                <Error404 />
            ) : (
                <>
                    <Switch>
                        {routes.map((route, i) => (
                            <Route {...route} key={i} />
                        ))}
                    </Switch>

                    <AuthModal isOpen={Boolean(currentAuthModal)} onClose={() => dispatch(setAuthModal(null))} />

                    <ToastContainer css={zIndex(ZIndex.TOAST)} autoClose={3000} icon={false} theme="colored" />
                </>
            )}
        </React.StrictMode>
    );
};
