import {IEntryCategory} from "../types/Category";
import {EntryType, IEntry} from "../types/Entry";
import {newshubApi} from "./newshub_api";

export interface IEntryRes {
    results: Array<IEntry>;
    count: number;
    page_size: number;
}

export interface IEntryVendorQuery {
    category: number;
    page: number;
    vendorId?: number;
    regionId?: ProvincialCity;
    dateFrom?: string;
    dateTo?: string;
    search?: string;
}

export interface IEntryTestQuery {
    category: Array<IEntryCategory>;
    page: number;
}

export enum ProvincialCity {
    WROCLAW = "T02",
    BYDGOSZCZ = "T04",
    LUBLIN = "T06",
    GORZOW_WIELKOPOLSKI = "T08",
    LODZ = "T10",
    KRAKOW = "T12",
    WARSZAWA = "T14",
    OPOLE = "T16",
    RZESZOW = "T18",
    BIALYSTOK = "T20",
    GDANSK = "T22",
    KATOWICE = "T24",
    KIELCE = "T26",
    OLSZTYN = "T28",
    POZNAN = "T30",
    SZCZECIN = "T32"
}

export const getEntriesApi = newshubApi.injectEndpoints({
    endpoints: (build) => ({
        getEntries: build.query<IEntryRes, void>({
            query: () => ({
                url: "/entries",
                method: "GET"
            })
        }),
        getEntriesByProvincialCity: build.query<IEntryRes, ProvincialCity | null>({
            query: (matchId) => ({
                url: `/entries/?match_id=${matchId}`,
                method: "GET"
            })
        }),
        getHighlightedEntries: build.query<IEntryRes, void>({
            query: () => ({
                url: `/entries/?is_highlighted=true&type=${EntryType.BIG_DATA_NEWS}`,
                method: "GET"
            })
        }),
        getEntriesById: build.query<IEntry, string>({
            query: (id) => ({
                url: `/entries/${id}`,
                method: "GET"
            })
        }),
        getEntriesByType: build.query<IEntryRes, number>({
            query: (type) => ({
                url: `/entries/?type=${type}&page_size=10`,
                method: "GET"
            })
        }),
        getArticles: build.query<IEntryRes, number>({
            query: (page) => ({
                url: `/entries/?type=${EntryType.ARTICLE}&page_size=10&page=${page}`,
                method: "GET"
            })
        }),
        getBigDataNews: build.query<IEntryRes, number>({
            query: (page) => ({
                url: `/entries/?type=${EntryType.BIG_DATA_NEWS}&page_size=5&page=${page}`,
                method: "GET"
            })
        }),
        getArticlesByCategory: build.query<IEntryRes, IEntryVendorQuery>({
            query: ({category, page = 1, vendorId, regionId, dateFrom, dateTo, search}) => ({
                url: `/entries/?type=${EntryType.ARTICLE}${
                    category > -1 ? `&category=${category}` : ""
                }&page=${page}&page_size=10${
                    (category === 1 || category === 2) && vendorId ? `&vendor=${vendorId}` : ""
                }${regionId ? `&match_id=${regionId}` : ""}${dateFrom ? `&date_from=${dateFrom}` : ""}${
                    dateTo ? `&date_to=${dateTo}` : ""
                }${search ? `&search=${search}` : ""}`,
                method: "GET"
            })
        }),
        getHighlightedArticlesByCategory: build.query<IEntryRes, {category: number}>({
            query: ({category}) => ({
                url: `/entries/?type=${EntryType.ARTICLE}&page_size=10?&is_highlighted=true${
                    category > -1 ? `&category=${category}` : ""
                }`,
                method: "GET"
            })
        }),
        getArticlesByCategories: build.query<IEntryRes, IEntryTestQuery>({
            query: ({category, page = 1}) => ({
                url: category
                    ? `/entries/?type=${EntryType.ARTICLE}&${category
                          .map((category) => `category=${category.id}`)
                          .join("&")}&page=${page}&page_size=10`
                    : `/entries/?type=${EntryType.ARTICLE}&page=${page}&page_size=10`,
                method: "GET"
            })
        })
    })
});

export const {
    useGetEntriesQuery,
    useGetEntriesByProvincialCityQuery,
    useGetHighlightedArticlesByCategoryQuery,
    useGetHighlightedEntriesQuery,
    useGetEntriesByIdQuery,
    useGetEntriesByTypeQuery,
    useGetBigDataNewsQuery,
    useGetArticlesByCategoryQuery,
    useGetArticlesByCategoriesQuery
} = getEntriesApi;
