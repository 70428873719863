import {IUserModel} from "../../../../../utils/shared_types/user_model";
import {usersApi} from "./usersApi";

type IUpdateCurrentUserPayload = Partial<Omit<IUserModel, "id">>;

export const updateCurrentUserApi = usersApi.injectEndpoints({
    endpoints: (build) => ({
        updateCurrentUser: build.mutation<IUserModel, IUpdateCurrentUserPayload>({
            query: (updateCurrentUserPayload) => ({
                url: `/me/`,
                method: "PUT",
                body: updateCurrentUserPayload
            })
        })
    }),
    overrideExisting: false
});

export const {useUpdateCurrentUserMutation} = updateCurrentUserApi;
