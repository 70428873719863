import {prefixPath} from "../helpers/prefix_path";

export const authApiUrl = process.env.AUTH_API_URL;

export const chartsApiPath = prefixPath("/api-charts/", {
    root: "",
    biggestRegionsStats: "biggest-regions-stats/",
    biggestRegionsTypeStats: "biggest-regions-type-stats/",
    monthAgglomerationStats: "month-agglomeration-stats/",
    monthAgglomerationTypeStats: "month-agglomeration-type-stats/",
    monthCityStats: "month-city-stats/",
    monthCityTypeStats: "month-city-type-stats/",
    monthDistrictStats: "month-district-stats/",
    monthDistrictTypeStats: "month-district-type-stats/",
    quarterCityStats: "quarter-city-stats/",
    quarterCityTypeStats: "quarter-city-type-stats/",
    quarterAgglomerationStats: "quarter-agglomeration-stats/",
    quarterAgglomerationTypeStats: "quarter-agglomeration-type-stats/",
    startedCityStats: "started-city-stats/",
    currentDate: "current-date/",
    monthGusStats: prefixPath("month-gus-stats/", {
        all: "all/",
        permits: "permits/",
        started: "started/",
        finished: "finished/"
    }),
    newsletter: "newsletter/"
});

export const backendApiPath = prefixPath("/api/", {
    cookies: "cookies/",
    users: prefixPath("users/", {
        me: "me/"
    }),
    monitoring: "monitoring/monitorings/",
    publications: "publications/",
    inquiry: "inquiry/",
    newshub: "newshub/"
});
