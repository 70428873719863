import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {Button} from "@pg-design/button";
import {FormikForm} from "@pg-design/formik-utils";
import {flexJustifyCenter, mb, mt, pointer, underline, w100} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {Text} from "@pg-design/text";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {bugsnagNotify} from "../../../../../../utils/bugsnag";
import {dataLayer, getRecentGTMKeyValue} from "../../../../../../utils/data_layer";
import {getPayloadError} from "../../../../../../utils/get_payload_error";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {IUserModel} from "../../../../../../utils/shared_types/user_model";
import {handleFormikSubmit} from "../../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../../components/SubmitFormikButton";
import {ApiError} from "../../../../store/IApiError";
import {loginUserComHit} from "../../../tracking/user_com/hit_user_com";
import {useLoginMutation} from "../../api/loginApi";
import {selectAuthFormsSharedData, setAuthFormsSharedData, setAuthModal} from "../../auth_slice";
import {DataProtectionNotice} from "../auth_modal/DataProtectionNotice";

export interface ILoginFormValues {
    email: string;
    password: string;
}

export const LoginForm = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const authFormsSharedData = useAppSelector(selectAuthFormsSharedData);
    const [login, loginMutation] = useLoginMutation({fixedCacheKey: "loginUser"});
    const initialValues: ILoginFormValues = {
        ...authFormsSharedData
    };

    useEffect(() => {
        return () => {
            loginMutation.reset();
        };
    }, []);

    const validationSchema = Yup.object({
        email: Yup.string().required(t("auth.errors.field_cannot_be_empty")),
        password: Yup.string().required(t("auth.errors.field_cannot_be_empty"))
    });

    const handleSubmit = (values: ILoginFormValues, helpers: FormikHelpers<ILoginFormValues>) =>
        handleFormikSubmit(
            login({
                email: values.email,
                password: values.password
            }).unwrap(),
            helpers,
            {
                onSuccess: () => {
                    const userData = loginMutation.data;
                    dataLayer({
                        event: "login_success",
                        user_id: loginMutation?.data?.id ?? "",
                        position: getRecentGTMKeyValue("position")
                    });
                    dispatch(setAuthModal(null));
                    loginUserComHit(userData as IUserModel);
                },
                onError: (error: ApiError) => {
                    const errors = error.data;
                    bugsnagNotify(loginMutation.error as Error, {source: "loginForm"});

                    if (errors && "email" in errors) {
                        helpers.setFieldError("email", getPayloadError(errors["email"], i18n));
                    }
                    if (errors && "password" in errors) {
                        helpers.setFieldError("password", getPayloadError(errors["password"], i18n));
                    }
                    if (errors && "non_field_errors" in errors && errors["non_field_errors"]) {
                        toast.error(getPayloadError(errors["non_field_errors"], i18n));
                    }

                    helpers.setSubmitting(false);
                }
            }
        );

    const handleRegisterLinkClick = () => {
        dispatch(setAuthModal("registerUser"));
        dataLayer({
            event: "register_modal_view",
            position: getRecentGTMKeyValue("position"),
            position_id: getRecentGTMKeyValue("position_id"),
            position_name: getRecentGTMKeyValue("position_name")
        });
    };

    return (
        <>
            <Modal.Header>
                <Text variant="headline_6" as="span">
                    {t("auth.login")}
                </Text>
            </Modal.Header>

            <Modal.Content>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({values, handleBlur, isSubmitting}) => (
                        <Form>
                            <FormikForm.Input
                                css={mb(1.5)}
                                onBlur={(e) => {
                                    handleBlur(e);
                                    dispatch(setAuthFormsSharedData(values));
                                }}
                                name="email"
                                placeholder="E-mail"
                                required
                            />

                            <FormikForm.Input
                                css={mb(1.5)}
                                onBlur={(e) => {
                                    handleBlur(e);
                                    dispatch(setAuthFormsSharedData(values));
                                }}
                                name="password"
                                placeholder={t("auth.password")}
                                type="password"
                                required
                            />

                            <Text
                                variant="info_txt_1"
                                align="right"
                                css={[underline, pointer, mb(3)]}
                                onClick={() => dispatch(setAuthModal("remindPassword"))}
                            >
                                {t("auth.remind_password")}
                            </Text>

                            <SubmitFormikButton css={w100} copy={t("auth.login")} disabled={isSubmitting} />
                        </Form>
                    )}
                </Formik>

                <Text css={[mt(3), mb(1.5)]} variant="info_txt_1" align="center">
                    {t("auth.no_account_yet")}
                </Text>

                <div css={[mb(5), w100, flexJustifyCenter]}>
                    <Button variant="highlight_primary" size="x-small" onClick={handleRegisterLinkClick}>
                        {t("auth.register")}
                    </Button>
                </div>

                <DataProtectionNotice isCenter />
            </Modal.Content>
        </>
    );
};
